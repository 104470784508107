import React from 'react';
import { MdDelete, MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { useDeleteMutation, useUpdateMutation } from '../../query';
import { toast } from 'react-toastify';

const QuizItem = ({
	quiz,
	selectedChapter,
	selectedSubject,
	allowDelete = false,
}) => {
	const deleteMutation = useDeleteMutation();
	const updateMutation = useUpdateMutation();

	const handleDelete = (quizId) => {
		deleteMutation.mutate(
			{
				id: quizId,
				url: 'quiz',
				queryKey: 'Quiz',
			},
			{
				onSuccess: () => {
					toast.success('Quiz deleted successfully!');
				},
				onError: () => {
					toast.error('Failed to delete quiz.');
				},
			}
		);
	};

	const updateQuiz = (quizId, updatedData) => {
		updateMutation.mutate({
			id: quizId,
			data: updatedData,
			url: 'quiz',
			queryKey: 'Quiz',
		});
	};

	const toggleActiveStatus = (id) => {
		// Find the subject and toggle its isActive status
		// const updatedSubjects = subjects.map((subject) =>
		// 	subject._id === id ? { ...subject, active: !subject.active } : subject
		// );
		// setSubjects(updatedSubjects);

		// // Call updateSubject with the new active status
		// const updatedSubject = updatedSubjects.find(
		// 	(subject) => subject._id === id
		// );

		const updatedQuiz = { ...quiz, active: !quiz.active };
		console.log(updatedQuiz);
		updateQuiz(id, {
			question: updatedQuiz.question,
			active: updatedQuiz.active,
		}); // Pass the new active status
	};

	return (
		<div
			key={quiz._id}
			className="w-full p-6 mb-4 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow"
		>
			<div className="flex justify-between items-center">
				<h4 className="text-xl font-semibold text-gray-800">{quiz.question}</h4>
				{allowDelete && (
					<div className="flex items-center justify-center space-x-4">
						{quiz.active ? (
							<MdVisibility
								onClick={() => toggleActiveStatus(quiz._id)}
								className="text-green-500 text-xl cursor-pointer"
							/>
						) : (
							<MdVisibilityOff
								onClick={() => toggleActiveStatus(quiz._id)}
								className="text-gray-500 text-xl cursor-pointer"
							/>
						)}
						<button
							className="text-red-500 hover:text-red-700"
							onClick={() => handleDelete(quiz._id)}
						>
							<MdDelete className="text-lg" />
						</button>
					</div>
				)}
			</div>
			<p className="text-gray-500 mt-2">
				Subject:{' '}
				<span className="font-medium text-gray-700">{selectedSubject}</span>
			</p>
			<p className="text-gray-500">
				Chapter:{' '}
				<span className="font-medium text-gray-700">{selectedChapter}</span>
			</p>
			<p className="text-gray-500">Options:</p>
			<ol className="list-decimal list-inside pl-4 font-medium text-gray-700 space-y-2">
				{quiz.options.map((option, index) => (
					<li key={index}>{option}</li>
				))}
			</ol>

			<p className="text-gray-500">
				Correct Answer:{' '}
				<span className="font-medium text-gray-700">
					Option {parseInt(quiz.correctAnswer) + 1}
				</span>
			</p>
		</div>
	);
};

export default QuizItem;
