import React, { useRef, useEffect } from 'react';
import CourseFieldsForm from './CourseFieldsForm';
import VideoPlayer from './VideoPlayer';
import VideoTimestamps from './VideoTimeStamps';
import TimestampForm from './TimestampForm';
import { translations } from '../../lib/translations';

const CourseForm = ({
	availableLanguages,
	subjects,
	chapters,
	setSubjects,
	setChapters,
	selectedSubject,
	setSelectedSubject,
	selectedChapter,
	setSelectedChapter,
	onLanguageChange,
	form, // The form object
	setForm, // Function to update the form in the parent component
	index, // Index of the current form
}) => {
	const fileInputRef = useRef(null);
	console.log(availableLanguages);

	useEffect(() => {
		console.log('form', form.selectedLanguage);
	}, [form]);

	const handleLanguageChange = (e) => {
		const languageValue = e.target.value;
		console.log(languageValue);
		onLanguageChange(index, languageValue);

		// Update the form's language
		setForm((prevForms) => {
			const updatedForms = [...prevForms];
			updatedForms[index] = {
				...updatedForms[index],
				selectedLanguage: languageValue,
			};
			return updatedForms;
		});
	};

	const handleFileChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			const objectUrl = URL.createObjectURL(file);
			// Update form's video file and source
			setForm((prevForms) => {
				const updatedForms = [...prevForms];
				updatedForms[index] = {
					...updatedForms[index],
					videoFile: file,
					videoSrc: objectUrl,
				};
				return updatedForms;
			});
		}
	};

	const handleRemoveVideo = () => {
		// Clear video file and source
		setForm((prevForms) => {
			const updatedForms = [...prevForms];
			updatedForms[index] = {
				...updatedForms[index],
				videoFile: null,
				videoSrc: null,
			};
			return updatedForms;
		});
		if (fileInputRef.current) {
			fileInputRef.current.value = ''; // Clear the input field
		}
	};

	return (
		<div className="p-6 space-y-6 mx-auto bg-white shadow-md rounded-lg border-2">
			<div className="mb-6">
				<label className="block text-gray-700 mb-1">
					{translations[form.selectedLanguage]?.selectLanguage}
				</label>
				<select
					className="p-2 border rounded w-full"
					value={form.selectedLanguage || ''}
					onChange={handleLanguageChange}
				>
					<option value="default" disabled>
						{translations[form.selectedLanguage]?.selectLanguage}
					</option>
					{Object.entries(availableLanguages).map(([code, name]) => (
						<option key={code} value={code}>
							{name}
						</option>
					))}
				</select>
			</div>

			{/* Other form fields */}
			<CourseFieldsForm
				subjects={subjects}
				chapters={chapters}
				setSubjects={setSubjects}
				setChapters={setChapters}
				selectedSubject={selectedSubject}
				setSelectedSubject={(subject) => {
					setSelectedSubject(subject);
					setForm((prevForms) => {
						const updatedForms = [...prevForms];
						updatedForms[index] = {
							...updatedForms[index],
							subject: subject?._id,
						};
						return updatedForms;
					});
				}}
				selectedChapter={selectedChapter}
				setSelectedChapter={(chapter) => {
					setSelectedChapter(chapter);
					setForm((prevForms) => {
						const updatedForms = [...prevForms];
						updatedForms[index] = {
							...updatedForms[index],
							chapter: chapter?._id,
						};
						return updatedForms;
					});
				}}
				courseTitle={form.title}
				description={form.description}
				setCourseTitle={(title) =>
					setForm((prevForms) => {
						const updatedForms = [...prevForms];
						updatedForms[index] = { ...updatedForms[index], title };
						return updatedForms;
					})
				}
				setDescription={(desc) =>
					setForm((prevForms) => {
						const updatedForms = [...prevForms];
						updatedForms[index] = { ...updatedForms[index], description: desc };
						return updatedForms;
					})
				}
				thumbnailFile={form.thumbnailFile}
				thumbnailSrc={form.thumbnailSrc}
				videoFile={form.videoFile}
				videoRef={fileInputRef}
				handleFileChange={handleFileChange}
				setThumbnailFile={(file) =>
					setForm((prevForms) => {
						const updatedForms = [...prevForms];
						updatedForms[index] = {
							...updatedForms[index],
							thumbnailFile: file,
						};
						return updatedForms;
					})
				}
				setThumbnailSrc={(src) =>
					setForm((prevForms) => {
						const updatedForms = [...prevForms];
						updatedForms[index] = { ...updatedForms[index], thumbnailSrc: src };
						return updatedForms;
					})
				}
				setSavedTimestamps={(timestamps) =>
					setForm((prevForms) => {
						const updatedForms = [...prevForms];
						updatedForms[index] = { ...updatedForms[index], timestamps };
						return updatedForms;
					})
				}
				selectedLanguage={form.selectedLanguage}
			/>

			{form.videoSrc && (
				<div className="flex flex-col gap-y-2">
					<VideoPlayer
						videoSrc={form.videoSrc}
						timestamps={form.timestamps}
						thumbnail={form.thumbnailSrc}
					/>
					<button
						className="mt-2 p-2 bg-red-500 text-white rounded"
						onClick={handleRemoveVideo}
					>
						{translations[form.selectedLanguage].removeVideo}
					</button>
				</div>
			)}

			<TimestampForm
				selectedLanguage={form.selectedLanguage}
				savedTimestamps={form.timestamps}
				setSavedTimestamps={(timestamps) =>
					setForm((prevForms) => {
						const updatedForms = [...prevForms];
						updatedForms[index] = { ...updatedForms[index], timestamps };
						return updatedForms;
					})
				}
				selectedSubject={selectedSubject}
				selectedChapter={selectedChapter}
			/>

			<VideoTimestamps
				savedTimestamps={form.timestamps}
				setSavedTimestamps={(timestamps) =>
					setForm((prevForms) => {
						const updatedForms = [...prevForms];
						updatedForms[index] = { ...updatedForms[index], timestamps };
						return updatedForms;
					})
				}
				selectedSubject={selectedSubject}
				selectedChapter={selectedChapter}
			/>
		</div>
	);
};

export default CourseForm;
