import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Layout from '../Layout';
import CourseCreation from '../pages/CourseCreation';
import Course from '../pages/Course';
import ErrorPage from '../pages/ErrorPage';
import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import Subject from '../pages/Subject';
import Quiz from '../pages/Quiz';
import Chapter from '../pages/Chapter';
import NotFound from '../pages/NotFound';

import PublicRoute from './PublicRoute';
import ProtectedRoute from './ProtectedRoute';
import VideoPreview from '../pages/VideoPreview';
import CourseQuiz from '../pages/CourseQuiz';

// Define your routes
const AppRoutes = () => {
	const { isAuthenticated } = useSelector((state) => state.auth);

	const router = createBrowserRouter([
		{
			path: '/',
			element: (
				<ProtectedRoute isAuthenticated={isAuthenticated}>
					<Layout>
						<Dashboard />
					</Layout>
				</ProtectedRoute>
			),
			errorElement: <ErrorPage />,
		},
		{
			path: '/course',
			element: (
				<ProtectedRoute isAuthenticated={isAuthenticated}>
					<Layout>
						<Course />
					</Layout>
				</ProtectedRoute>
			),
			errorElement: <ErrorPage />,
		},
		{
			path: '/create-course',
			element: (
				<ProtectedRoute isAuthenticated={isAuthenticated}>
					<Layout>
						<CourseCreation />
					</Layout>
				</ProtectedRoute>
			),
			errorElement: <ErrorPage />,
		},
		{
			path: '/quiz',
			element: (
				<ProtectedRoute isAuthenticated={isAuthenticated}>
					<Layout>
						<Quiz />
					</Layout>
				</ProtectedRoute>
			),
			errorElement: <ErrorPage />,
		},
		{
			path: '/subject',
			element: (
				<ProtectedRoute isAuthenticated={isAuthenticated}>
					<Layout>
						<Subject />
					</Layout>
				</ProtectedRoute>
			),
			errorElement: <ErrorPage />,
		},
		{
			path: '/chapters',
			element: (
				<ProtectedRoute isAuthenticated={isAuthenticated}>
					<Layout>
						<Chapter />
					</Layout>
				</ProtectedRoute>
			),
			errorElement: <ErrorPage />,
		},
		{
			path: '/preview-video/:id',
			element: (
				<ProtectedRoute isAuthenticated={isAuthenticated}>
					<Layout>
						<VideoPreview />
					</Layout>
				</ProtectedRoute>
			),
			errorElement: <ErrorPage />,
		},
		{
			path: '/course/quiz/:id',
			element: (
				<PublicRoute>
					{/* <Layout> */}
					<CourseQuiz />
					{/* </Layout> */}
				</PublicRoute>
			),
			errorElement: <ErrorPage />,
		},
		{
			path: '/login',
			element: (
				<PublicRoute>
					<Login />
				</PublicRoute>
			),
			errorElement: <ErrorPage />,
		},
		{
			path: '*',
			element: <NotFound />,
		},
	]);

	return <RouterProvider router={router} />;
};

export default AppRoutes;
