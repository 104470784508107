import React, { useState, useEffect, useRef } from 'react';
import { MdDelete, MdVisibility, MdVisibilityOff } from 'react-icons/md';
import {
	useCreateMutation,
	useDeleteMutation,
	useGetQuery,
	useUpdateMutation,
} from '../query';
import { ToastContainer, toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners'; // Import a loader component
import { uploadThumbnail } from '../aws/s3';

function Subject() {
	const [subjects, setSubjects] = useState([]); // To store subjects
	const [newSubject, setNewSubject] = useState(''); // New subject input
	const [className, setClassName] = useState(''); // New state for className
	const [thumbnailFile, setThumbnailFile] = useState(null);
	const [thumbnailSrc, setThumbnailSrc] = useState(null);
	const fileInputRef = useRef(null);

	const { data, error, isLoading } = useGetQuery({
		queryKey: ['Subject'], // Include currentPage and pageSize in queryKey
		url: `subject`,
	});
	const createMutation = useCreateMutation();
	const deleteMutation = useDeleteMutation();
	const updateMutation = useUpdateMutation();

	useEffect(() => {
		if (data) {
			setSubjects(data?.subjects);
		}
	}, [data]);

	// Validate that className contains only numbers and letters
	const handleClassNameChange = (e) => {
		const value = e.target.value;
		const alphanumericRegex = /^[a-zA-Z0-9]*$/; // Regex to allow only letters and numbers
		if (alphanumericRegex.test(value)) {
			setClassName(value);
		} else {
			toast.error('Class name can only contain letters and numbers.');
		}
	};

	// Handle new subject input
	const handleAddSubject = async () => {
		if (newSubject.trim() === '') {
			toast.error('Please enter a subject name');
			return;
		}

		if (!className.trim()) {
			toast.error('Please enter a valid class name (letters and numbers only)');
			return;
		}

		if (!thumbnailSrc) {
			toast.error('Please upload a thumbnail');
			return;
		}

		// Check if subject already exists
		if (
			subjects.find(
				(subject) => subject?.title?.toLowerCase() === newSubject?.toLowerCase()
			)
		) {
			toast.error('Subject already exists');
			return;
		}

		// Upload thumbnail
		const responseThumbnail = await uploadThumbnailFile(
			thumbnailFile,
			newSubject,
			thumbnailFile.type
		);

		if (!responseThumbnail) {
			toast.error('Error uploading file.');
			return;
		}

		setSubjects([
			...subjects,
			{ name: newSubject.trim(), thumbnail: responseThumbnail },
		]);
		setNewSubject('');
		setClassName('');
		setThumbnailFile(null);
		setThumbnailSrc(null);
		fileInputRef.current.value = null;

		createMutation.mutate({
			data: {
				title: newSubject,
				className: className, // Send className to backend
				thumbnail: responseThumbnail,
			},
			url: 'subject',
			queryKey: 'Subject',
		});
	};

	const deleteSubject = (subjectId) => {
		deleteMutation.mutate({
			id: subjectId,
			url: 'subject',
			queryKey: 'Subject',
		});
	};

	const updateSubject = (subjectId, updatedData) => {
		updateMutation.mutate({
			id: subjectId,
			data: updatedData,
			url: 'subject',
			queryKey: 'Subject',
		});
	};

	const toggleActiveStatus = (id) => {
		// Find the subject and toggle its isActive status
		const updatedSubjects = subjects.map((subject) =>
			subject._id === id ? { ...subject, active: !subject.active } : subject
		);
		setSubjects(updatedSubjects);

		// Call updateSubject with the new active status
		const updatedSubject = updatedSubjects.find(
			(subject) => subject._id === id
		);
		updateSubject(id, {
			title: updatedSubject.title,
			active: updatedSubject.active,
		}); // Pass the new active status
	};

	// Thumbnail preview
	const handleThumbnailChange = (file) => {
		if (file) {
			const maxSizeInBytes = 3 * 1024 * 1024; // 3 MB
			if (file.size > maxSizeInBytes) {
				alert('Please upload an image smaller than 3 MB.');
				setThumbnailFile(null);
				setThumbnailSrc(null);
				fileInputRef.current.value = null;
				return;
			}

			const img = new Image();
			const reader = new FileReader();

			reader.onloadend = () => {
				img.src = reader.result;
			};

			img.onload = () => {
				if (img.width > img.height) {
					// Landscape image
					setThumbnailFile(file);
					setThumbnailSrc(img.src);
				} else {
					// Portrait image
					alert('Please upload a landscape image.');
					setThumbnailFile(null);
					setThumbnailSrc(null);
					fileInputRef.current.value = null;
				}
			};

			reader.readAsDataURL(file);
		}
	};

	const handleRemoveThumbnail = (e) => {
		setThumbnailFile(null);
		setThumbnailSrc(null);
		fileInputRef.current.value = null;
	};

	const uploadThumbnailFile = async (file, name, type) => {
		const response = await uploadThumbnail(file, name, type);
		return response;
	};

	const handleFileDrop = (e) => {
		e.preventDefault();
		e.stopPropagation();
		const files = e.dataTransfer.files;
		if (files.length > 0) {
			handleThumbnailChange(files[0]);
		}
	};

	const handleDragOver = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	return (
		<div className="p-6 space-y-6 max-w-2xl mx-auto bg-white shadow-md rounded-md">
			{/* Add Subject Section */}
			<ToastContainer />
			<div className="mb-6">
				<h2 className="text-2xl font-semibold mb-4 w-full text-center">
					Manage Subjects
				</h2>
				<input
					type="text"
					className="p-2 border rounded w-full"
					placeholder="Enter subject name"
					value={newSubject}
					onChange={(e) => setNewSubject(e.target.value)}
				/>
				<input
					type="text"
					className="p-2 border rounded w-full mt-4"
					placeholder="Enter class name (letters and numbers only)"
					value={className}
					onChange={handleClassNameChange}
				/>
				<div className="mt-4">
					<label className="block text-gray-700">
						Upload Thumbnail (Landscape) (3 MB max):
					</label>
					<div
						className="border-2 border-dashed border-gray-300 rounded-lg p-4 flex flex-col items-center justify-center cursor-pointer hover:border-blue-500"
						onClick={() => fileInputRef.current.click()}
						onDrop={handleFileDrop}
						onDragOver={handleDragOver}
					>
						<input
							type="file"
							accept="image/*"
							className="hidden"
							onChange={(e) => handleThumbnailChange(e.target.files[0])}
							ref={fileInputRef}
						/>
						<p className="text-gray-500 mb-2">
							Drag and drop your image here or click to select
						</p>
						{thumbnailSrc ? (
							<img
								src={thumbnailSrc}
								alt="Thumbnail preview"
								className="w-full h-auto rounded-lg mb-2"
							/>
						) : (
							<p className="text-gray-400">No file selected</p>
						)}
					</div>
					{thumbnailSrc && (
						<button
							onClick={handleRemoveThumbnail}
							className="mt-1 bg-red-500 text-white p-2 rounded hover:bg-red-600 transition"
						>
							Remove
						</button>
					)}
				</div>
				<button
					className="mt-4 bg-green-500 text-white p-2 rounded"
					onClick={handleAddSubject}
				>
					Save Subject
				</button>
			</div>

			{/* Loader */}
			{isLoading ? (
				<div className="flex justify-center items-center h-48">
					<ClipLoader size={50} color={'#4A90E2'} />
				</div>
			) : (
				<ul>
					{subjects.map((subject) => (
						<li key={subject._id} className="p-4 border-b flex justify-between">
							<span>{subject.title}</span>
							<span>{subject.className}</span>
							<div className="flex items-center justify-center space-x-4">
								{subject.active ? (
									<MdVisibility
										onClick={() => toggleActiveStatus(subject._id)}
										className="text-green-500 text-xl cursor-pointer"
									/>
								) : (
									<MdVisibilityOff
										onClick={() => toggleActiveStatus(subject._id)}
										className="text-gray-500 text-xl cursor-pointer"
									/>
								)}
								<MdDelete
									onClick={() => deleteSubject(subject._id)}
									className="text-red-500 text-xl cursor-pointer"
								/>
							</div>
						</li>
					))}
				</ul>
			)}
		</div>
	);
}

export default Subject;
