import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';

const VideoPlayerWithTimestamps = ({ videoSrc, timestamps, thumbnail }) => {
	const [playing, setPlaying] = useState(false);
	const [currentTimestamp, setCurrentTimestamp] = useState(null);
	const [currentTime, setCurrentTime] = useState(0);
	const [showResumeButton, setShowResumeButton] = useState(false);
	const [showQuiz, setShowQuiz] = useState(false);
	const [resumeTimer, setResumeTimer] = useState(null); // State for the countdown
	const [alreadyStopped, setAlreadyStopped] = useState(false);

	// Function to handle progress updates
	const handleProgress = (state) => {
		setCurrentTime(state.playedSeconds);

		// Find the active timestamp based on the current time
		const activeTimestamp = timestamps.find(
			(timestamp) =>
				state.playedSeconds >= parseTime(timestamp.from) &&
				state.playedSeconds < parseTime(timestamp.to)
		);

		if (activeTimestamp?.to !== currentTimestamp?.to) {
			setAlreadyStopped(false);
		}

		if (activeTimestamp) {
			setCurrentTimestamp(activeTimestamp);
			setShowQuiz(false); // Ensure the MCQ is hidden when the video is playing

			// Pause the video if current time exceeds the "to" value of the active timestamp
			if (
				state.playedSeconds + 0.8 >= parseTime(activeTimestamp.to) &&
				!alreadyStopped
			) {
				setPlaying(false); // Pause video
				setShowQuiz(true);
				setAlreadyStopped(true);

				if (
					activeTimestamp.timeBound === 'true' &&
					activeTimestamp.timer
				) {
					setResumeTimer(activeTimestamp.timer); // Start the countdown
				} else if (activeTimestamp.timeBound === 'false') {
					setShowResumeButton(true); // Show resume button
				}
			}
		} else {
			setCurrentTimestamp(null);
			setShowResumeButton(false); // Hide resume button
			setShowQuiz(false); // Hide MCQ if no active timestamp
			setAlreadyStopped(false);
		}
	};

	// Parse time (format "minutes:seconds") to seconds
	const parseTime = (time) => {
		const [hours, minutes, seconds] = time.split(':').map(Number);
		return hours * 3600 + minutes * 60 + seconds;
	};

	// Countdown effect for resume timer
	useEffect(() => {
		if (resumeTimer !== null && resumeTimer > 0) {
			const timer = setInterval(() => {
				setResumeTimer((prev) => prev - 1);
			}, 1000);

			return () => clearInterval(timer); // Cleanup the interval on component unmount
		} else if (resumeTimer === 0) {
			setPlaying(true); // Resume video once the timer ends
			setResumeTimer(null);
			setAlreadyStopped(false);
		}
	}, [resumeTimer]);

	// Resume video manually
	const handleResumeClick = () => {
		setPlaying(true);
		setShowResumeButton(false);
	};

	return (
		<div className="flex flex-col items-center bg-gray-900 p-4 rounded-xl shadow-lg">
			{/* Video player */}
			<div className="relative w-full max-w-3xl">
				<ReactPlayer
					url={videoSrc}
					playing={playing}
					controls={true}
					onPlay={() => setPlaying(true)}
					onPause={() => setPlaying(false)}
					light={
						thumbnail && (
							<img src={thumbnail} alt="thumbnail" width="100%" height="100%" />
						)
					}
					style={{ borderRadius: '8px' }}
					width="100%"
					height="100%"
					onProgress={handleProgress} // Track progress for timestamp control
					className="rounded-md overflow-hidden min-h-[300px] min-w-[500px]"
				/>

				{/* Resume button for timeBound "false" */}
				{showResumeButton && (
					<div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-70">
						<button
							onClick={handleResumeClick}
							className="px-4 py-2 bg-blue-600 text-white rounded-lg"
						>
							Resume Video
						</button>
					</div>
				)}

				{/* Show countdown if timeBound is true */}
				{resumeTimer !== null && (
					<div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-70">
						<p className="px-4 py-2 bg-gray-800 text-white rounded-lg">
							Resuming in {resumeTimer} seconds...
						</p>
					</div>
				)}
			</div>

			{/* Timestamp display */}
			<div className="mt-4 text-gray-300 text-sm w-full">
				{currentTimestamp && (
					<>
						<p className="text-center w-full">
							<span className="text-white">
								Timestamp Title: {currentTimestamp.title}
							</span>
						</p>
						{showQuiz && (
							<div className="w-full flex flex-col">
								<h3 className="text-white">Quiz</h3>
								<div className="p-4 bg-gray-800 rounded-md w-full">
									<p className="text-white text-xl font-bold mb-2">
										{currentTimestamp.quiz.question}
									</p>
									<div className="space-y-2">
										{currentTimestamp.quiz.options.map((option, index) => (
											<div key={index} className="flex items-center">
												<input
													type="radio"
													id={`option-${index}`}
													name="quiz-options"
													value={option}
													className="mr-2"
												/>
												<label
													htmlFor={`option-${index}`}
													className="text-white"
												>
													{option}
												</label>
											</div>
										))}
									</div>
								</div>
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default VideoPlayerWithTimestamps;
