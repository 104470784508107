import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetQuery } from '../query';
import io from 'socket.io-client';
import { SOCKET_BASE_URL } from '../lib/constant';

const CourseQuiz = () => {
	const { id: courseId } = useParams();
	const [socket, setSocket] = useState(null);
	const [course, setCourse] = useState({});
	const [currentTimestamp, setCurrentTimestamp] = useState({});
	const [action, setAction] = useState('');
	const [language, setLanguage] = useState('en');
	const [selectedOption, setSelectedOption] = useState(null);
	const [isAnswerSelected, setIsAnswerSelected] = useState(false);
	const [isCorrect, setIsCorrect] = useState(null);

	const { data, error, isLoading } = useGetQuery({
		queryKey: ['Course'],
		url: `course/unauth/${courseId}`,
	});

	// This useEffect handles fetching the course data
	useEffect(() => {
		if (data) {
			setCourse(data.course);
		}
	}, [data]);

	// This useEffect handles WebSocket connection only after course data is loaded
	useEffect(() => {
		if (Object.keys(course).length > 0) {
			// Connect to socket server
			const newSocket = io(`${SOCKET_BASE_URL}`, {
				transports: ['websocket', 'polling'],
			});
			setSocket(newSocket);

			// Join room based on roomId (QR code or course ID)
			newSocket.emit('joinRoom', { roomId: courseId, isHost: false });

			// Listen for quiz event
			newSocket.on('timestampUpdate', (data) => {
				console.log('Quiz event received:', data);
				if (data?.language) setLanguage(data?.language);
				if (data?.action === 'hide') {
					setAction(data?.action);
					resetQuizState();
				} else {
					setAction(data?.action);
				}

				const current = course?.localizedContent?.[
					data?.language
				]?.timestamps?.find((timestamp) => timestamp._id === data?.timestampId);
				setCurrentTimestamp(current);
			});

			return () => {
				newSocket.disconnect(); // Cleanup on component unmount
			};
		}
	}, [course, courseId]);

	const resetQuizState = () => {
		setSelectedOption(null);
		setIsAnswerSelected(false);
		setIsCorrect(null);
	};

	const handleOptionSelect = (optionIndex) => {
		if (isAnswerSelected) return; // Prevent further selections

		setSelectedOption(optionIndex);
		setIsAnswerSelected(true);
		socket.emit('quizAnswer', {
			roomId: courseId,
			timestampId: currentTimestamp._id,
			answer: optionIndex,
		});

		// Check if selected option is correct
		if (parseInt(currentTimestamp?.quiz?.correctAnswer) === optionIndex) {
			setIsCorrect(true);
		} else {
			setIsCorrect(false);
		}
	};

	return (
		<div className="w-full p-8 bg-white max-w-2xl rounded-xl mx-auto">
			<h1 className="text-3xl font-bold text-center mb-8">
				Course Quiz Preview
			</h1>
			<p className="text-xl font-semibold text-center mb-8">
				The currently connected course details are below
			</p>

			<div className="flex-1 flex justify-between items-start mb-6">
				{/* Course Description */}
				<div className="w-3/4 flex flex-col gap-2">
					<p className="text-gray-700 w-full">
						<strong>Title:</strong>{' '}
						{course?.localizedContent?.[language]?.title ||
							'No Title available.'}
					</p>
					<p className="text-gray-700">
						<strong>Description:</strong>{' '}
						{course?.localizedContent?.[language]?.description ||
							'No description available.'}
					</p>
				</div>
			</div>

			{/* Quiz Details */}
			<p className="text-xl font-semibold text-center mb-8">
				Your Quiz will appear here below:
			</p>

			{action === 'display' && (
				<div className="flex flex-col gap-4 mb-6">
					{currentTimestamp && (
						<h2 className="text-lg text-center">
							<strong>Timestamp Title:</strong> {currentTimestamp?.title}
						</h2>
					)}
					{currentTimestamp && (
						<h2 className="text-base text-left">
							<strong>Question:</strong> {currentTimestamp?.quiz?.question}
						</h2>
					)}
					{currentTimestamp && (
						<h2 className="text-base text-left">
							<strong>Options:</strong>
						</h2>
					)}
					{currentTimestamp?.quiz?.options?.map((option, index) => (
						<label
							className={`flex items-center gap-4 p-4 border rounded-lg shadow-md hover:bg-gray-100 cursor-pointer ${
								selectedOption === index && 'bg-gray-200'
							}`}
							key={index}
						>
							<input
								type="radio"
								name="option"
								value={index}
								className="form-radio h-5 w-5 text-blue-600"
								disabled={isAnswerSelected} // Disable radio after selection
								onChange={() => handleOptionSelect(index)}
								checked={selectedOption === index} // Mark as checked if selected
							/>
							<span className="text-gray-700">{option}</span>
						</label>
					))}

					{isAnswerSelected && (
						<div className="mt-4 text-center">
							<p className="font-bold">
								You selected option {selectedOption + 1}.
							</p>
							{isCorrect ? (
								<p className="text-green-500 font-bold">Correct Answer!</p>
							) : (
								<p className="text-red-500 font-bold">Wrong Answer.</p>
							)}
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default CourseQuiz;
