import { createSlice } from '@reduxjs/toolkit';

// Helper functions to get and set state in localStorage
const getStoredUser = () => JSON.parse(localStorage.getItem('videoProject')) || null;
const getStoredToken = () => localStorage.getItem('videoProjectToken') || null;

const initialState = {
  isAuthenticated: !!getStoredToken(), // true if token exists
  user: getStoredUser(),
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload.user;
      localStorage.setItem('videoProject', JSON.stringify(action.payload.user));
      localStorage.setItem('videoProjectToken', action.payload.token);
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      localStorage.removeItem('videoProject');
      localStorage.removeItem('videoProjectToken');
    },
  },
});

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;