// apiMutations.js

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createRequest, deleteRequest, getRequest, updateRequest } from './api';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

export const useCreateMutation = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: ({ data, url, queryKey }) => createRequest(data, url),
		onSuccess: (response, { queryKey }) => {
			toast.success(response.data.message);
			queryClient.invalidateQueries({ queryKey: [queryKey] });
		},
		onError: (error) => {
			toast.error(error.response.data.message || 'An error occurred');
		},
	});
};

export const useUpdateMutation = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: ({ id, data, url, queryKey }) => updateRequest(data, url, id),
		onSuccess: (response, { queryKey }) => {
			toast.success(response.data.message);
			queryClient.invalidateQueries({ queryKey: [queryKey] });
			if (queryKey === 'admin') {
				localStorage.setItem('user', JSON.stringify(response.data.admin));
			}
		},
		onError: (error) => {
			toast.error(error.response.data.message || 'An error occurred');
		},
	});
};

export const useDeleteMutation = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: ({ id, url, queryKey }) => deleteRequest(id, url),
		onSuccess: (response, { queryKey }) => {
			toast.success(response.data.message);
			queryClient.invalidateQueries({ queryKey: [queryKey] });
		},
		onError: (error) => {
			toast.error(error.response.data.message || 'An error occurred');
		},
	});
};

export const useGetQuery = ({ queryKey, url, enabled = true, ...options }) => {
	return useQuery({
		queryKey,
		queryFn: async () => {
			if (!enabled) return; // If not enabled, skip the query
			const response = await getRequest(url);
			if (response.status !== 200) {
				throw new Error('Network response was not ok');
			}
			return response.data;
		},
		enabled,
		...options, // Pass enabled as a configuration option
	});
};
