import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { useGetQuery } from '../query';
import { ClipLoader } from 'react-spinners';

function Dashboard() {
	const navigate = useNavigate();
	const user = useSelector((state) => state.auth.user);

	const { data, error, isLoading } = useGetQuery({
		queryKey: ['dashboard'],
		url: 'user/dashboard',
	});

	console.log({ data });

	if (!user) return <div>Unauthorized</div>;

	if (isLoading)
		return (
			<div>
				<ClipLoader color="#4A90E2" size={100} />
			</div>
		);

	return (
		<div className="w-full h-[100vh-100px] p-4 max-w-5xl">
			<div className="w-full justify-between flex gap-6 ">
				<div className="flex flex-col gap-6 h-full">
					<div
						className="flex flex-col max-w-48 min-w-44 gap-1 rounded-2xl items-center justify-center px-8 py-4 bg-white"
						style={{ boxShadow: '1.5px 2.99px 8.98px 0px #6434F826' }}
					>
						<h1 className="text-lg font-bold text-center">NUMBER OF COURSES</h1>
						<p className="text-lg font-bold text-[#5342E4]">
							{data?.data?.courseCount}
						</p>
					</div>

					<div
						className="flex flex-col max-w-48 min-w-44 gap-6 rounded-2xl items-center justify-center p-6 px-3 bg-gradient-to-b text-white"
						style={{
							boxShadow: '1.5px 2.99px 8.98px 0px #6434F826',
							background: 'linear-gradient(180deg, #5A48F9 0%, #312787 100%)',
						}}
					>
						<h1 className="text-lg font-bold text-left">
							Latest Courses Created
						</h1>
						<ol className="flex flex-col gap-1 list-decimal items-start w-full pl-6">
							{data?.data?.lastThreeCourses?.slice(0, 3).map((course) => (
								<li key={course._id} className="text-lg font-bold w-full">
									{course?.localizedContent?.en?.title ||
										course?.localizedContent?.hi?.title ||
										course?.localizedContent?.bn?.title}
								</li>
							))}
						</ol>
					</div>
				</div>

				<div className="flex flex-col gap-6 h-full">
					<div
						className="flex flex-col max-w-48 min-w-44 gap-2 rounded-2xl items-center justify-center pt-6 bg-[#312787]"
						style={{ boxShadow: '1.5px 2.99px 8.98px 0px #6434F826' }}
					>
						<div
							className="flex flex-col max-w-48 min-w-44 gap-2 rounded-2xl items-center justify-center pt-6 bg-[#5A48F9]"
							style={{ boxShadow: '1.5px 2.99px 8.98px 0px #6434F826' }}
						>
							<div
								className="flex flex-col max-w-48 min-w-44 gap-1 rounded-2xl items-center justify-center px-8 py-4 bg-white cursor-pointer hover:bg-gray-100 hover:shadow-lg hover:scale-105 transition-all duration-300"
								style={{ boxShadow: '1.5px 2.99px 8.98px 0px #6434F826' }}
								onClick={() => navigate('/create-course')}
							>
								<h1 className="text-lg font-bold text-center">CREATE COURSE</h1>
								{/* <p className="text-xs font-medium text-center">
									(this is the button to create a course)
								</p> */}
							</div>
						</div>
					</div>
					<div
						className="flex flex-col max-w-48 min-w-44 gap-6 rounded-2xl items-center justify-center p-6 px-3 bg-white"
						style={{
							boxShadow: '1.5px 2.99px 8.98px 0px #6434F826',
						}}
					>
						<h1 className="text-lg font-bold text-left">Latest Quiz Created</h1>
						<ol className="flex flex-col gap-1 list-decimal items-start w-full pl-6">
							{data?.data?.lastThreeQuiz?.map((quiz) => (
								<li key={quiz._id} className="text-lg font-bold w-full">
									{quiz?.question?.split(' ').slice(0, 6).join(' ') +
										(quiz?.question?.split(' ').length > 6 ? '...' : '')}
								</li>
							))}
						</ol>
					</div>
				</div>

				<div className="flex flex-col min-w-[600px] gap-6">
					<div className="flex gap-6 ">
						<div
							className="flex flex-col max-w-48 min-w-44 gap-1 rounded-2xl items-center justify-center px-8 py-4 bg-white"
							style={{ boxShadow: '1.5px 2.99px 8.98px 0px #6434F826' }}
						>
							<h1 className="text-lg font-bold text-center">
								NUMBER OF QUIZZES
							</h1>
							<p className="text-lg font-bold text-[#5342E4]">
								{data?.data?.quizCount}
							</p>
						</div>
						<div
							className="flex flex-col max-w-48 min-w-44 gap-1 rounded-2xl items-center justify-center px-8 py-4 bg-white"
							style={{ boxShadow: '1.5px 2.99px 8.98px 0px #6434F826' }}
						>
							<h1 className="text-lg font-bold text-center">
								NUMBER OF SUBJECTS
							</h1>
							<p className="text-lg font-bold text-[#5342E4]">
								{data?.data?.subjectCount}
							</p>
						</div>
						<div
							className="flex flex-col max-w-48 min-w-44 gap-1 rounded-2xl items-center justify-center px-8 py-4 bg-white"
							style={{ boxShadow: '1.5px 2.99px 8.98px 0px #6434F826' }}
						>
							<h1 className="text-lg font-bold text-center">
								NUMBER OF CHAPTERS
							</h1>
							<p className="text-lg font-bold text-[#5342E4]">
								{data?.data?.chapterCount}
							</p>
						</div>
					</div>

					<div
						className="flex flex-col gap-2 rounded-2xl items-center justify-center p-6 px-4 pl-2 pt-2 bg-white"
						style={{
							boxShadow: '1.5px 2.99px 8.98px 0px #6434F826',
						}}
					>
						<h1 className="font-bold text-lg w-full pl-6">
							Latest Video Uploaded.
						</h1>
						<div className="flex flex-col items-center bg-gray-900 p-4 rounded-xl shadow-lg">
							<ReactPlayer
								url={
									data?.data?.lastThreeCourses[0]?.localizedContent?.en
										?.video ||
									data?.data?.lastThreeCourses[0]?.localizedContent?.hi
										?.video ||
									data?.data?.lastThreeCourses[0]?.localizedContent?.bn?.video
								}
								// url="https://tikao-admin-dev.s3.ap-south-1.amazonaws.com/videos/1727356194472-alco%20rest.mp4"
								controls={true}
								width="100%"
								height="100%"
								className="rounded-md overflow-hidden min-h-[200px] min-w-[400px]"
							/>
						</div>
					</div>
				</div>
			</div>

			<div
				className="w-full bg-white shadow-lg rounded-2xl p-6 mt-8"
				style={{ boxShadow: '1.5px 2.99px 8.98px 0px #6434F826' }}
			></div>
		</div>
	);
}

export default Dashboard;
