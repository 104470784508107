import React from 'react';
import { Ellipse } from '../../assets';
import { useSelector } from 'react-redux';

const Navbar = () => {
	const user = useSelector((state) => state.auth.user);

	return (
		<nav className="bg-white shadow-md p-4 flex justify-between items-center">
			{/* Search bar */}
			<div className="relative w-full max-w-xs flex flex-row">
				<p className="text-3xl font-bold ps-4">Tikao Admin</p>
			</div>

			{/* User avatar */}
			<div className="flex items-center space-x-4">

				<div>
					<p className="text-gray-700 text-lg font-semibold">{user.name}</p>
					<span className="text-gray-700">INSTRUCTOR</span>
				</div>
				<img
					src={Ellipse}
					alt="User Avatar"
					className="w-10 h-10 rounded-full"
				/>
			</div>
		</nav>
	);
};

export default Navbar;
