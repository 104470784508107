import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Sidebar from './components/Layout/Sidebar';
import Navbar from './components/Layout/Navbar';

const Layout = ({ children }) => {
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
	const location = useLocation();

	const isLoginPage = location.pathname === '/login'; // Check if on login page

	return (
		<div className="flex h-screen">
			{/* Sidebar (hidden on login page) */}
			{!isLoginPage && <Sidebar />}

			{/* Main content area */}
			<div className="flex-1 flex flex-col w-[calc(100%-96px)] ml-24">
				{/* Navbar (hidden on login page) */}
				{!isLoginPage && <Navbar />}

				{/* Main content (children are passed from the router) */}
				<main className="p-4 bg-gray-100 flex-grow">{children}</main>
			</div>
		</div>
	);
};

export default Layout;
