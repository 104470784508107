import React from 'react';
import { Link } from 'react-router-dom';
import { ChapterSVG, CourseSVG, SubjectSVG, QuizSVG, HomeSVG } from '../../assets';
import './sidebar.css';

const Sidebar = () => {
	return (
		<div
			className={`flex-shrink-0 w-24 bg-white flex flex-col items-center h-screen justify-center fixed border-r-2`}
		>
			{/* Sidebar links */}
			<nav className="w-full">
				<ul className="design flex flex-col relative w-20 items-center justify-center space-y-8 py-14">
					<li>
						<Link to="/" className="" title='Home'>
							<img
								src={HomeSVG}
								alt="User Avatar"
								className="size-6 rounded-full"
							/>
						</Link>
					</li>
					<li>
						<Link to="/subject" className="" title="Subjects">
							<img
								src={SubjectSVG}
								alt="User Avatar"
								className="size-6 rounded-full"
							/>
						</Link>
					</li>
					<li>
						<Link to="/chapters" className="" title="Chapters">
							<img
								src={ChapterSVG}
								alt="User Avatar"
								className="size-6 rounded-full"
							/>
						</Link>
					</li>
					<li>
						<Link to="/quiz" className="" title="Quiz">
							<img
								src={QuizSVG}
								alt="User Avatar"
								className="size-6 rounded-full"
							/>
						</Link>
					</li>
					<li>
						<Link to="/course" className="" title="Courses">
							<img
								src={CourseSVG}
								alt="User Avatar"
								className="size-6 rounded-full"
							/>
						</Link>
					</li>
				</ul>
			</nav>
		</div>
	);
};

export default Sidebar;
