import axios from 'axios';
import { API_BASE_URL } from './lib/constant';
import { toast } from 'react-toastify';

// Create an axios instance
const apiClient = axios.create({
	baseURL: API_BASE_URL,
});

// Add a request interceptor to include the token in headers
apiClient.interceptors.request.use(
	(config) => {
		const token = localStorage.getItem('videoProjectToken'); // Or get it from Redux state
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	},
	(error) => Promise.reject(error)
);

// Add a response interceptor to handle errors globally
apiClient.interceptors.response.use(
	(response) => response, // If the response is successful, just return it
	(error) => {
		// Check if the error response contains "invalid token"
		if (error.response && error.response.data.message === 'Invalid token') {
			// Clear the token from localStorage
			localStorage.removeItem('videoProjectToken');
			localStorage.removeItem('videoProject');

			// Optional: Show a notification to the user
			toast.error('Session expired. Please log in again.');

			// Redirect the user to the login page
			window.location.href = '/login'; // Adjust the path as per your app's routing
		}

		// Return a rejected promise to propagate the error to calling code
		return Promise.reject(error);
	}
);

export const createRequest = (data, url) => {
	return apiClient.post(`/${url}`, data);
};

export const updateRequest = (data, url, id) => {
	return apiClient.put(`/${url}/${id}`, data);
};

export const deleteRequest = (roleId, url) => {
	return apiClient.delete(`/${url}/${roleId}`);
};

export const getRequest = (url) => {
	return apiClient.get(`/${url}`);
};
