import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';

const PreviewVideoPlayer = ({ course, selectedLanguage, socket, courseId }) => {
	const [playing, setPlaying] = useState(false);
	const [currentTimestamp, setCurrentTimestamp] = useState(null);
	const [currentTime, setCurrentTime] = useState(0);
	const [showResumeButton, setShowResumeButton] = useState(false);
	const [resumeTimer, setResumeTimer] = useState(null); // State for the countdown
	const [alreadyStopped, setAlreadyStopped] = useState(false);
	const [showQuiz, setShowQuiz] = useState(false);
	const [showAnswer, setShowAnswer] = useState(false); // for showing answer
	const [receivedAnswer, setReceivedAnswer] = useState(null); // Store received answer

	// Function to handle progress updates
	const handleProgress = (state) => {
		setCurrentTime(state.playedSeconds);

		// Find the active timestamp based on the current time
		const activeTimestamp = course?.timestamps.find(
			(timestamp) =>
				state.playedSeconds >= parseTime(timestamp.from) &&
				state.playedSeconds < parseTime(timestamp.to) + 0.5
		);

		if (activeTimestamp?.to !== currentTimestamp?.to) {
			setAlreadyStopped(false);
		}

		if (activeTimestamp) {
			setCurrentTimestamp(activeTimestamp);

			// Pause the video if current time exceeds the "to" value of the active timestamp
			if (
				state.playedSeconds + 0.8 >= parseTime(activeTimestamp.to) &&
				!alreadyStopped
			) {
				setPlaying(false); // Pause video
				setAlreadyStopped(true);

				socket.emit('timestampStop', {
					roomId: courseId,
					timestampId: activeTimestamp._id,
					action: 'display',
					language: selectedLanguage,
				});
				console.log(activeTimestamp);

				if (activeTimestamp.timeBound && activeTimestamp.timer) {
					setResumeTimer(activeTimestamp.timer); // Start the countdown
				} else if (activeTimestamp.timeBound === false) {
					setShowResumeButton(true); // Show resume button
				}
				setShowQuiz(true);
			}
		} else {
			setCurrentTimestamp(null);
			setShowResumeButton(false); // Hide resume button
			setShowQuiz(false);
			setAlreadyStopped(false);
		}
	};

	// Parse time (format "minutes:seconds") to seconds
	const parseTime = (time) => {
		const [hours, minutes, seconds] = time.split(':').map(Number);
		return hours * 3600 + minutes * 60 + seconds;
	};

	// Countdown effect for resume timer
	useEffect(() => {
		if (resumeTimer !== null && resumeTimer > 0) {
			const timer = setInterval(() => {
				setResumeTimer((prev) => prev - 1);
			}, 1000);

			return () => clearInterval(timer); // Cleanup the interval on component unmount
		} else if (resumeTimer === 0) {
			socket.emit('timestampStart', {
				roomId: courseId,
				timestampId: currentTimestamp._id,
				action: 'hide',
			});
			setPlaying(true); // Resume video once the timer ends
			setResumeTimer(null);
			setShowQuiz(false);
			setShowAnswer(false);
			setReceivedAnswer(null);
		}
	}, [resumeTimer]);

	// Resume video manually
	const handleResumeClick = () => {
		socket.emit('timestampStart', {
			roomId: courseId,
			timestampId: currentTimestamp._id,
			action: 'hide',
		});
		setPlaying(true);
		setShowResumeButton(false);
		setShowAnswer(false);
		setShowQuiz(false);
		setReceivedAnswer(null);
	};

	// Listen for quizAnswer event
	useEffect(() => {
		if (socket) {
			socket.on('quizAnswer', ({ timestampId, answer }) => {
				console.log(`Answer received for timestamp ${timestampId}:`, answer);
				setReceivedAnswer(answer); // Store the received answer
				setShowAnswer(true); // Show the modal with the answer
			});
		}
	}, [socket]);

	return (
		<div className="flex flex-col items-center bg-gray-900 p-4 rounded-xl shadow-lg">
			{/* Video player */}
			<div className="relative w-full max-w-3xl">
				<ReactPlayer
					url={course?.video}
					playing={playing}
					controls={true}
					onPlay={() => setPlaying(true)}
					onPause={() => setPlaying(false)}
					light={
						course?.thumbnail && (
							<img
								src={course?.thumbnail}
								alt="thumbnail"
								width="100%"
								height="100%"
							/>
						)
					}
					style={{ borderRadius: '8px' }}
					width="100%"
					height="100%"
					onProgress={handleProgress} // Track progress for timestamp control
					className="rounded-md overflow-hidden min-h-[300px] min-w-[500px]"
				/>
			</div>

			{/* Timestamp display */}
			<div className="mt-4 text-gray-300 text-sm w-full">
				{currentTimestamp && (
					<>
						<p className="text-center w-full">
							<span className="text-white">
								Timestamp Title: {currentTimestamp.title}
							</span>
						</p>
					</>
				)}
			</div>

			{/* Modal to display the received quiz answer */}
			{showQuiz && (
				<div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50 min-w-80">
					<div className="bg-white p-8 rounded-lg max-w-2xl w-2/3 mx-auto shadow-xl">
						<h2 className="text-3xl font-bold mb-4 w-full text-center">Quiz</h2>
						<div className="flex flex-col gap-4 mb-4">
							<h2 className="text-lg text-center">
								<strong>Timestamp Title:</strong> {currentTimestamp?.title}
							</h2>
							<h2 className="text-base text-left">
								<strong>Question:</strong> {currentTimestamp?.quiz?.question}
							</h2>
							<h2 className="text-base text-left">
								<strong>Options:</strong>
							</h2>
							{currentTimestamp?.quiz?.options?.map((option, index) => (
								<label
									className={`flex items-center gap-4 p-4 border rounded-lg shadow-md hover:bg-gray-100 cursor-pointer ${
										receivedAnswer === index && 'bg-gray-200'
									}`}
									key={index}
								>
									<input
										type="radio"
										name="option"
										value={index}
										className="form-radio h-5 w-5 text-blue-600"
										// disabled={isAnswerSelected} // Disable radio after selection
										// onChange={() => handleOptionSelect(index)}
										checked={receivedAnswer === index} // Mark as checked if selected
									/>
									<span className="text-gray-700">{option}</span>
								</label>
							))}
						</div>
						{showAnswer && (
							<p className="text-lg mb-4">
								Received answer:{' '}
								<strong>
									{currentTimestamp?.quiz?.options[receivedAnswer]}
								</strong>
								(Option: <strong>{receivedAnswer + 1}</strong>)
							</p>
						)}
						{showAnswer && (
							<p className="text-lg mb-4">
								{/* Check if the received answer matches the correct option */}
								{receivedAnswer ===
								parseInt(currentTimestamp?.quiz?.correctAnswer) ? (
									<span className="text-green-500 font-bold">
										The answer is Correct!
									</span>
								) : (
									<span className="text-red-500">
										The answer is wrong, the correct answer is{' '}
										<strong>
											{
												currentTimestamp?.quiz?.options[
													parseInt(currentTimestamp?.quiz?.correctAnswer)
												]
											}
										</strong>
										(Option:{' '}
										<strong>
											{parseInt(currentTimestamp?.quiz?.correctAnswer) + 1}
										</strong>
										)
									</span>
								)}
							</p>
						)}
						{/* Resume button for timeBound "false" */}
						{showResumeButton && (
							<button
								onClick={handleResumeClick}
								className="px-4 py-2 bg-blue-600 text-white rounded-lg w-full"
							>
								Resume Video
							</button>
						)}

						{/* Show countdown if timeBound is true */}
						{resumeTimer !== null && (
							<p className="px-4 py-2 bg-gray-800 text-white rounded-lg text-center">
								Resuming in {resumeTimer} seconds...
							</p>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default PreviewVideoPlayer;
