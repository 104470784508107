import React, { useState } from 'react';
import { useCreateMutation } from '../../query';
import { toast } from 'react-toastify';
import { MdDelete } from 'react-icons/md';

const QuizForm = ({
	selectedChapter,
	setSelectedChapter,
	setSelectedSubject,
}) => {
	const [question, setQuestion] = useState('');
	const [options, setOptions] = useState(['', '']);
	const [correctAnswer, setCorrectAnswer] = useState('');

	const createMutation = useCreateMutation();

	// Handle option change
	const handleOptionChange = (index, value) => {
		const updatedOptions = options.map((option, i) =>
			i === index ? value : option
		);
		setOptions(updatedOptions);
	};

	// Add option to the options list
	const handleAddOption = () => {
		if (options.length < 6) {
			setOptions([...options, '']);
		}
	};

	const handleRemoveOption = (index) => {
		const updatedOptions = options.filter((_, i) => i !== index);
		setOptions(updatedOptions);
	};

	// Save the quiz data
	const handleSaveQuiz = () => {
		// const newQuestionID = `Q${Math.floor(Math.random() * 10000)}`;
		// setQuestionID(newQuestionID);

		if (!selectedChapter) {
			toast.error('Please select a chapter.');
			return;
		}

		if (!question) {
			toast.error('Please enter a question.');
			return;
		}

		if (options.length < 2) {
			toast.error('Please add at least two options.');
			return;
		}

		// Check for empty options
		for (const option of options) {
			if (!option.trim()) {
				// Checks if the option is empty or only whitespace
				toast.error('Options should not be empty.');
				return;
			}
		}

		if (!correctAnswer) {
			toast.error('Please select a correct answer.');
			return;
		}

		const quizData = {
			// questionID: newQuestionID,
			// subject: selectedSubject,
			chapter: selectedChapter,
			question,
			options,
			correctAnswer,
		};

		createMutation.mutate(
			{
				url: 'quiz',
				data: quizData,
				queryKey: 'Quiz',
			},
			{
				onSuccess: () => {
					// toast.success('Quiz saved successfully!');
					resetForm();
				},
				onError: () => {
					toast.error('Failed to save quiz.');
				},
			}
		);
	};

	// Reset form state
	const resetForm = () => {
		setQuestion('');
		setOptions(['', '']);
		setCorrectAnswer('');
	};

	return (
		<div>
			<div className="mb-6">
				<label className="block text-gray-700 mb-2">Question</label>
				<input
					type="text"
					className="p-2 border rounded w-full"
					placeholder="Enter your question"
					value={question}
					onChange={(e) => setQuestion(e.target.value)}
				/>
			</div>

			<div className="mb-6">
				<div className="flex items-center mb-2">
					<label className="block text-gray-700 w-full">Options</label>
					<span className="block text-gray-700 ml-2 w-1/5 text-center">
						Correct Option
					</span>
				</div>
				{options.map((option, index) => (
					<div key={index} className="mb-2 flex items-center">
						<input
							type="text"
							className="p-2 border rounded w-full mr-2"
							placeholder={`Option ${index + 1}`}
							value={option}
							onChange={(e) => handleOptionChange(index, e.target.value)}
						/>
						<input
							type="radio"
							name="correctAnswer"
							value={index}
							checked={correctAnswer === index.toString()}
							onChange={() => setCorrectAnswer(index.toString())}
							className="border-2 rounded w-1/5 p-2 size-6"
						/>
						<MdDelete
							onClick={() => handleRemoveOption(index)}
							className="text-red-500 text-xl cursor-pointer"
						/>
					</div>
				))}
				{options.length < 6 && (
					<button
						className="bg-blue-500 text-white p-2 rounded"
						onClick={handleAddOption}
					>
						Add More Options
					</button>
				)}
			</div>

			<button
				className="bg-green-500 text-white p-2 rounded"
				onClick={handleSaveQuiz}
			>
				Save Quiz
			</button>
		</div>
	);
};

export default QuizForm;
