import React, { useState, useEffect } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { useGetQuery } from '../query';
import { REACT_APP_BASE_URL, SOCKET_BASE_URL } from '../lib/constant';
import { ClipLoader } from 'react-spinners';
import { useParams } from 'react-router-dom';
import PreviewVideoPlayer from '../components/PreviewVideoPlayer';
import io from 'socket.io-client';
import { translations } from '../lib/translations';

const VideoPreview = () => {
	const { id: courseId } = useParams();
	const [languages, setLanguages] = useState({
		en: 'English',
		hi: 'Hindi',
		bn: 'Bengali',
	});
	const [selectedLanguage, setSelectedLanguage] = useState('en');
	const videoUrl = `${REACT_APP_BASE_URL}/course/quiz/${courseId}`;
	const [course, setCourse] = useState({});
	const [qrCodeUrl, setQrCodeUrl] = useState('');
	const [socket, setSocket] = useState(null);

	const { data, error, isLoading } = useGetQuery({
		queryKey: ['Course'],
		url: `course/${courseId}`,
	});

	useEffect(() => {
		// Connect to socket server
		const newSocket = io(`${SOCKET_BASE_URL}`, {
			transports: ['websocket'],
			reconnectionAttempts: 5, // Optional: set reconnection attempts
			reconnectionDelay: 2000,
		});
		setSocket(newSocket);
		// Join room based on roomId (QR code or course ID)
		newSocket.emit('joinRoom', { roomId: courseId, isHost: true });

		return () => {
			newSocket.disconnect(); // Cleanup on component unmount
		};
	}, [courseId]);

	useEffect(() => {
		setQrCodeUrl(videoUrl); // Set the QR code URL
	}, [videoUrl]);

	useEffect(() => {
		if (data) {
			setCourse(data.course);
			// Filter languages based on available content
			const availableLanguages = Object.keys(languages).filter(
				(lang) => data.course?.localizedContent?.[lang]
			);
			setLanguages((prev) => {
				const filteredLanguages = {};
				availableLanguages.forEach((lang) => {
					filteredLanguages[lang] = prev[lang];
				});
				return filteredLanguages;
			});

			if (availableLanguages.length > 0) {
				setSelectedLanguage(availableLanguages[0]);
			}
		}
	}, [data]);

	const handleLanguageChange = (e) => {
		setSelectedLanguage(e.target.value);
	};

	if (isLoading) return <ClipLoader color="#000000" />;

	return (
		<div className="w-full p-8 bg-white max-w-6xl rounded-xl">
			{/* Course Title */}
			<h1 className="text-3xl font-bold text-center mb-8">Course Preview</h1>
			{/* Course Description (Left) and Language Dropdown (Right) */}
			<div className="flex-1 flex justify-between items-start mb-6">
				{/* Course Description */}
				<div className="w-3/4 flex flex-col gap-2 mb-6">
					<p className="text-gray-700 w-full">
						<strong>{translations[selectedLanguage]?.['title']}:</strong>{' '}
						{course?.localizedContent?.[selectedLanguage]?.title ||
							'No Title available.'}
					</p>
					<p className="text-gray-700">
						<strong>
							{translations[selectedLanguage]?.['descriptionshort']}:
						</strong>{' '}
						{course?.localizedContent?.[selectedLanguage]?.description ||
							'No description available.'}
					</p>
				</div>

				{/* Language Dropdown */}
				<div className="flex flex-col">
					<label htmlFor="language" className="mb-2 font-semibold">
						Select Language:
					</label>
					<select
						id="language"
						value={selectedLanguage}
						onChange={handleLanguageChange}
						className="p-2 border rounded-md shadow-sm bg-gray-100"
					>
						{Object.keys(languages).map((lang) => (
							<option key={lang} value={lang}>
								{languages[lang]}
							</option>
						))}
					</select>
				</div>
			</div>

			<div className="w-full flex gap-8">
				<div className="w-full flex gap-8">
					{/* QR Code Section */}
					<div className="flex flex-col w-1/4">
						<h3 className="text-xl font-semibold text-center mb-6">
							Scan this QR code to watch the video on your mobile:
						</h3>
						<QRCodeSVG value={qrCodeUrl} size={256} />
					</div>

					{/* Video Section */}
					<div className="flex flex-col w-3/4">
						<PreviewVideoPlayer
							course={course?.localizedContent?.[selectedLanguage]}
							selectedLanguage={selectedLanguage}
							socket={socket}
							courseId={courseId}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default VideoPreview;
