import React from 'react';
import { MdDelete } from 'react-icons/md';
import QuizItem from '../quiz/QuizItem';

const VideoTimestamps = ({
	savedTimestamps,
	setSavedTimestamps,
	selectedSubject,
	selectedChapter,
}) => {
	console.log(savedTimestamps);
	const removeTimestamp = (e, index) => {
		e.preventDefault();

		const changedTimestamps = savedTimestamps.filter((_, i) => i !== index);
		setSavedTimestamps(changedTimestamps);
	};

	return (
		<div>
			<h3 className="text-lg font-semibold">Saved Timestamps</h3>
			{savedTimestamps.length > 0 &&
				savedTimestamps?.map((timestamp, index) => (
					<div
						key={index}
						className="p-4 bg-gray-100 rounded-md mt-4 space-y-4"
					>
						{/* Timestamp Title */}
						<div className="flex justify-between items-center">
							<h4 className="text-xl font-semibold text-gray-800">
								Timestamp # {index + 1}
							</h4>
							<button
								className="hover:bg-red-700 mt-4 p-2 bg-red-500 text-white rounded"
								onClick={(e) => removeTimestamp(e, index)}
							>
								<MdDelete className="text-lg size-4" />
							</button>
						</div>
						<div>
							<label className="block text-gray-700">Timestamp Title</label>
							<div className="w-full p-2 border-2 rounded mt-2 bg-gray-100">
								{timestamp.title || 'No title set'}
							</div>
						</div>

						{/* Time Range */}
						<div className="flex space-x-4">
							<div className="w-1/2 grid grid-cols-2 items-center">
								<label className="block text-gray-700 col-span-1">From: </label>
								<div className="w-full p-2 border-2 rounded mt-2 bg-gray-100 col-span-1">
									{timestamp.from}
								</div>
							</div>
							<div className="w-1/2 grid grid-cols-2 items-center">
								<label className="block text-gray-700 col-span-1">To: </label>
								<div className="w-full p-2 border-2 rounded mt-2 bg-gray-100 col-span-1">
									{timestamp.to}
								</div>
							</div>
						</div>

						{/* Time Bound */}
						<div className="grid grid-cols-2 items-center">
							<label className="block text-gray-700 col-span-1">
								Time Bound
							</label>
							<div className="w-full p-2 border-2 rounded mt-2 bg-gray-100 col-span-1">
								{timestamp.timeBound === 'true' ? 'Yes' : 'No'}
							</div>
						</div>

						{/* If Time Bound is Yes, show resume time */}
						{timestamp.timeBound === 'true' && (
							<div className="grid grid-cols-2 items-center">
								<label className="block text-gray-700 col-span-1">
									Resume After (seconds)
								</label>
								<div className="w-full p-2 border-2 rounded mt-2 bg-gray-100 col-span-1">
									{timestamp.timer}
								</div>
							</div>
						)}

						{/* Quiz Display */}
						{timestamp.quiz && (
							<QuizItem
								quiz={timestamp.quiz}
								selectedChapter={selectedChapter.title}
								selectedSubject={selectedSubject.title}
							/>
						)}
					</div>
				))}
		</div>
	);
};

export default VideoTimestamps;
