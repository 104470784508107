import React, { useEffect, useState } from 'react';
import { useGetQuery } from '../../query';
import QuizItem from '../quiz/QuizItem';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { translations } from '../../lib/translations';

const INITIAL_TIMESTAMP = {
	title: '',
	from: '0:00:00',
	to: '0:00:00',
	timeBound: 'false',
	timer: '',
	quiz: null,
};

const INITIAL_TIMERANGE = {
	hh: '00',
	mm: '00',
	ss: '00',
};

const TimestampForm = ({
	selectedLanguage,
	savedTimestamps,
	setSavedTimestamps,
	selectedSubject,
	selectedChapter,
}) => {
	const [timestamp, setTimestamp] = useState(INITIAL_TIMESTAMP);
	const [timerange, setTimerange] = useState({
		from: INITIAL_TIMERANGE,
		to: INITIAL_TIMERANGE,
	});
	const [quizzes, setQuizzes] = useState([]);
	const [availableQuizzes, setAvailableQuizzes] = useState([]);

	// Fetch quizzes
	const { data: quizData, isLoading: quizLoading } = useGetQuery({
		queryKey: ['Quiz', selectedChapter?._id],
		url: selectedChapter?._id
			? `quiz/chapter/${selectedChapter._id}`
			: undefined,
		enabled: !!selectedChapter?._id,
	});

	useEffect(() => {
		if (quizData) {
			setQuizzes(quizData.quiz);
			const q = quizzes.filter(
				(quiz) =>
					!savedTimestamps.some((timestamp) => timestamp.quiz?._id === quiz._id)
			);
			setAvailableQuizzes(q);
		}
	}, [quizData, quizzes, savedTimestamps]);

	// Handle form input changes for the single timestamp
	const handleInputChange = (field, value) => {
		setTimestamp((prev) => ({ ...prev, [field]: value }));
	};

	const handleTimerangeChange = (field, type, value) => {
		const numValue = parseInt(value);

		if (isNaN(numValue) || numValue < 0) return;

		// Handle validation for hours, minutes, and seconds
		if (type === 'hh' && numValue > 9) return; // Only allow 0-9 for hours
		if (type === 'mm' && numValue > 59) return; // Only allow 0-59 for minutes
		if (type === 'ss' && numValue > 59) return; // Only allow 0-59 for seconds

		const paddedValue = type !== 'hh' ? value.padStart(2, '0') : value;

		setTimerange((prev) => ({
			...prev,
			[field]: { ...prev[field], [type]: paddedValue },
		}));

		const formattedValue = `${type === 'hh' ? value : timerange[field].hh || '0'}:${type === 'mm' ? paddedValue : timerange[field].mm || '00'}:${type === 'ss' ? paddedValue : timerange[field].ss || '00'}`;
		setTimestamp((prev) => ({
			...prev,
			[field]: formattedValue,
		}));
	};

	const handleAddQuiz = (e, quiz) => {
		e.preventDefault();
		setTimestamp((prev) => ({ ...prev, quiz }));

		setAvailableQuizzes((prevQuizzes) =>
			prevQuizzes.filter((q) => q._id !== quiz._id)
		);
	};

	const handleRemoveQuiz = () => {
		setTimestamp((prev) => ({ ...prev, quiz: null }));

		const removedQuiz = timestamp.quiz; // Get the removed quiz
		if (removedQuiz) {
			setAvailableQuizzes((prevQuizzes) => [...prevQuizzes, removedQuiz]);
		}
	};

	const parseTime = (time) => {
		const [hours, minutes, seconds] = time.split(':').map(Number);
		return hours * 3600 + minutes * 60 + seconds;
	};

	// Validate from and to times
	const handleTimeValidation = () => {
		if (parseTime(timestamp.from) >= parseTime(timestamp.to)) {
			console.log(parseTime(timestamp.from), parseTime(timestamp.to));
			return false;
		}
		return true;
	};

	const validateForm = () => {
		if (timestamp.title === '') {
			toast.error('Title cannot be empty.');
			return false;
		}
		if (!handleTimeValidation()) {
			toast.error('"From" time cannot be greater than or equal to "To" time.');
			return false;
		}
		if (timestamp.timeBound === 'true' && timestamp.timer === '') {
			toast.error('Resume time cannot be empty.');
			return false;
		}
		return true;
	};

	const handleSaveTimestamp = (e) => {
		e.preventDefault();

		// Check if the number of timestamps exceeds the limit (7)
		if (savedTimestamps.length >= 7) {
			toast.error('You can only add up to 7 timestamps.');
			return;
		}

		console.log(timestamp);

		// Validate from and to times
		if (!validateForm()) return;

		// Add the current timestamp to the savedTimestamps array
		const newTimestamps = Array.isArray(savedTimestamps)
			? [...savedTimestamps, timestamp] // Spread the existing timestamps and add the new one
			: [timestamp];
		setSavedTimestamps(newTimestamps);

		// Reset the form after saving
		setTimestamp({ ...INITIAL_TIMESTAMP, from: timestamp.to });
		setTimerange({
			from: timerange.to,
			to: INITIAL_TIMERANGE,
		});
		toast.success('Timestamp saved successfully!');
	};

	return (
		<div>
			<h3 className="text-lg font-semibold">
				{translations[selectedLanguage]?.timestampTagging}
			</h3>
			<div className="p-4 bg-gray-50 rounded-md mt-4 space-y-4">
				{/* Timestamp Title */}
				<div>
					<label className="block text-gray-700">
						{translations[selectedLanguage]?.timestampTitle}
					</label>
					<input
						type="text"
						className="w-full p-2 border rounded mt-2"
						value={timestamp.title}
						onChange={(e) => handleInputChange('title', e.target.value)}
					/>
				</div>

				{/* Time Range */}
				<div className="flex space-x-4">
					{/* From Time Fields */}
					<div className="w-1/2">
						<label className="block text-gray-700">
							{translations[selectedLanguage]?.from} (hh:mm:ss)
						</label>
						<div className="flex space-x-2">
							<input
								type="number"
								className="p-2 border rounded w-1/3"
								value={timerange?.from?.hh || '0'}
								onChange={(e) =>
									handleTimerangeChange('from', 'hh', e.target.value)
								}
								placeholder="h"
							/>
							<input
								type="number"
								className="p-2 border rounded w-1/3"
								value={timerange?.from?.mm.padStart(2, '0') || ''}
								onChange={(e) =>
									handleTimerangeChange('from', 'mm', e.target.value)
								}
								placeholder="mm"
							/>
							<input
								type="number"
								className="p-2 border rounded w-1/3"
								value={timerange?.from?.ss.padStart(2, '0') || ''}
								onChange={(e) =>
									handleTimerangeChange('from', 'ss', e.target.value)
								}
								placeholder="ss"
							/>
						</div>
					</div>

					{/* To Time Fields */}
					<div className="w-1/2">
						<label className="block text-gray-700">
							{translations[selectedLanguage]?.to} (hh:mm:ss)
						</label>
						<div className="flex space-x-2">
							<input
								type="number"
								className="p-2 border rounded w-1/3"
								value={timerange?.to?.hh || ''}
								onChange={(e) =>
									handleTimerangeChange('to', 'hh', e.target.value)
								}
								placeholder="h"
							/>
							<input
								type="number"
								className="p-2 border rounded w-1/3"
								value={timerange.to.mm.padStart(2, '0') || ''}
								onChange={(e) =>
									handleTimerangeChange('to', 'mm', e.target.value)
								}
								placeholder="mm"
							/>
							<input
								type="number"
								className="p-2 border rounded w-1/3"
								value={timerange.to.ss.padStart(2, '0') || ''}
								onChange={(e) =>
									handleTimerangeChange('to', 'ss', e.target.value)
								}
								placeholder="ss"
							/>
						</div>
					</div>
				</div>

				{/* Time Bound */}
				<div>
					<label className="block text-gray-700">
						{translations[selectedLanguage]?.timeBound}
					</label>
					<select
						className="w-full p-2 border rounded mt-2"
						value={timestamp.timeBound}
						onChange={(e) => handleInputChange('timeBound', e.target.value)}
					>
						<option value="false">No</option>
						<option value="true">Yes</option>
					</select>
				</div>

				{/* Resume Time (only show if timeBound is true) */}
				{timestamp.timeBound === 'true' && (
					<div>
						<label className="block text-gray-700">
							{translations[selectedLanguage]?.resumeAfterSeconds}
						</label>
						<input
							type="number"
							className="w-full p-2 border rounded mt-2"
							value={timestamp.timer}
							onChange={(e) => handleInputChange('timer', e.target.value)}
							max={120}
							min={30}
						/>
					</div>
				)}

				{/* Quiz Display */}
				{timestamp.quiz && (
					<div className="flex flex-col justify-between items-center bg-gray-200 p-2 rounded mt-4">
						<QuizItem
							quiz={timestamp.quiz}
							selectedChapter={selectedChapter?.title}
							selectedSubject={selectedSubject?.title}
						/>
						<button
							onClick={handleRemoveQuiz}
							className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition"
						>
							{translations[selectedLanguage]?.remove}
						</button>
					</div>
				)}

				{quizLoading ? (
					<ClipLoader color="#000000" />
				) : (
					<div className="mt-8">
						<h2 className="text-2xl font-bold mb-4">
							{translations[selectedLanguage]?.chooseQuizToAdd}
						</h2>
						{availableQuizzes.map((quiz) => (
							<div
								key={quiz._id}
								className="flex justify-between mb-2 items-center"
							>
								<QuizItem
									quiz={quiz}
									selectedChapter={selectedChapter?.title}
									selectedSubject={selectedSubject?.title}
								/>
								<button
									className="bg-blue-500 text-white px-4 py-4 rounded-lg ml-4 h-full hover:bg-blue-600 hover:shadow-lg transition ease-in-out duration-150"
									onClick={(e) => handleAddQuiz(e, quiz)}
								>
									+
								</button>
							</div>
						))}
					</div>
				)}

				{/* Save Timestamp Button */}
				<button
					type="submit"
					onClick={handleSaveTimestamp}
					className="w-full mt-6 p-2 bg-green-600 text-white rounded"
				>
					{translations[selectedLanguage]?.saveTimestamp}
				</button>
			</div>
		</div>
	);
};

export default TimestampForm;
